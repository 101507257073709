<template>
  <form
    v-if="tattooerStudio"
    action
    id="info-share"
    data-vv-scope="tattooer-form"
    @submit.prevent="validateForm('tattooer-form')"
  >
    <v-row>
      <v-col cols="12">
        <p>{{ $t("info_share_tattooer") }}</p>
        <ul>
          <li
            v-for="(t, i) in $t('info_share_tattooers')"
            :key="i"
            v-text="t"
          ></li>
        </ul>
      </v-col>
      <v-col cols="12" md="8" class="mb-10 mb-sm-0">
        <v-checkbox
          v-model="tattooerStudio.share"
          v-validate="'required|is:true'"
          data-vv-name="share"
          :error-messages="errors.collect('tattooer-form.share')"
          :label="$t('share_info')"
          true-value="true"
          false-value="false"
          hide-details
          on-icon="mdi-check-circle"
          off-icon="mdi-circle-outline"
        >
          <template v-slot:label>
            <div style="margin-top: 2px">
              {{ $t("share_info") }}
              <a
                @click.stop
                target="blank"
                :href="studio_page.value == 'link' ? link.value : URL"
              >
                {{ $t("check_condicions_links") }}
              </a>
            </div>
          </template></v-checkbox
        >
      </v-col>
    </v-row>
    <div class="pa-3 text-right" v-if="buttons !== false">
      <v-btn outlined style="height: 25px" @click="$emit('back')">
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-btn
        class="ml-3"
        type="submit"
        style="
          width: 100px !important;
          color: #363533;
          height: 25px;
          padding-top: 10px !important;
        "
        elevation="0"
      >
        <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: "" }) }}
      </v-btn>
    </div>
  </form>
</template>

<script>
import * as moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  name: "InformationShare",
  props: ["tattooerStudio", "buttons"],

  components: {
    Timetable: () => import("@/components/ui/Timetable"),
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
    this.fetchStudios();
  },
  computed: {
    ...mapState("inscriptions", ["studioState"]),

    studio: {
      get() {
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },
    studio_page() {
       let text = this.studioState.user.setting.find((x) => {
        return x.value == "link";
      });
      console.log(text);

      return text ? text : "none";
    },
    link() {
      let policy = this.studioState.user.setting.find((x) => {
        return x.key == "link_appointment";
      });
      console.log(policy);
      return policy;
    },
    URL() {
      return `${window.location.origin}/?#/inscription/policy/studio/${this.studioState.token}`;
    },
  },
  methods: {
    async validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          //console.log("validated");
          this.$emit("validated", this.tattooerStudio);
          return true;
        }
        return false;
      });
    },
  },
  data() {
    return { menuFrom: false, menuTo: false, today: null };
  },
};
</script>
<style lang="sass">
#info-share
  .v-input--selection-controls__input .v-icon
    margin-top: -23px !important
  label
    font-size: 14px !important
</style>